import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Chip,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Autocomplete,
  CircularProgress,
  Alert,
  Grid,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import TimerIcon from '@mui/icons-material/Timer';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import axios from 'axios';

const cuisinesList = ['Mexican', 'Italian', 'Chinese', 'Indian', 'American'];
const dietaryRestrictionsList = ['Gluten-Free', 'Vegan', 'Vegetarian', 'Keto', 'Paleo'];
const apiUrl = process.env.REACT_APP_API_URL || 'https://api.shelfchef.co.uk/generate-recipe';

const App: React.FC = () => {
  const [ingredients, setIngredients] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [cuisine, setCuisine] = useState<string | null>(null);
  const [dietaryRestriction, setDietaryRestriction] = useState<string | null>(null);
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null);
  const [servings, setServings] = useState<number | null>(null);
  const [recipes, setRecipes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleAddIngredient = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      setIngredients([...ingredients, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveIngredient = (ingredientToRemove: string) => {
    setIngredients(ingredients.filter(ingredient => ingredient !== ingredientToRemove));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setRecipes([]);
    try {
      const response = await axios.post(apiUrl, {
        ingredients,
        cuisine,
        dietary_restrictions: dietaryRestriction,
        estimated_time_minutes: estimatedTime,
        servings,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      // Adjusted the way recipes are accessed
      const returnedRecipes = response.data.recipes?.[0] || [];

      if (returnedRecipes.length > 0) {
        setRecipes(returnedRecipes);
      } else {
        setError('No recipes found for the provided criteria.');
      }
    } catch (err: any) {
      setError(err.message || 'Oops something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#009B7D', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Container
        sx={{
          width: '90%', // Adjusted width for better mobile fit
          backgroundColor: '#ECECEA',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          fontFamily: 'Abhaya Libre, serif',
          padding: { xs: '10px', sm: '20px' }, // Smaller padding on mobile
        }}
      >
        <Box sx={{ textAlign: 'center', marginBottom: '10px' }}>
          <img src="/logo.svg" alt="Shelf Chef Logo" style={{ maxWidth: '150px', marginBottom: '10px' }} /> {/* Smaller logo for mobile */}
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sm={3}> {/* Smaller grid item size for mobile */}
                <Autocomplete
                  options={cuisinesList}
                  freeSolo
                  value={cuisine}
                  onChange={(event, newValue) => setCuisine(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cuisine"
                      variant="outlined"
                      sx={{
                        borderRadius: '16px', // Reduced border-radius on mobile
                        fontSize: { xs: '0.8rem', sm: '1rem' }, // Responsive font size
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalDiningIcon sx={{ color: '#FFBF5D', fontSize: { xs: '1rem', sm: '1.5rem' } }} /> {/* Smaller icon */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Autocomplete
                  options={dietaryRestrictionsList}
                  freeSolo
                  value={dietaryRestriction}
                  onChange={(event, newValue) => setDietaryRestriction(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dietary Restriction"
                      variant="outlined"
                      sx={{
                        borderRadius: '16px',
                        fontSize: { xs: '0.8rem', sm: '1rem' },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#FFBF5D', fontSize: { xs: '1rem', sm: '1.5rem' } }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Estimated Time"
                  type="number"
                  value={estimatedTime || ''}
                  onChange={(e) => setEstimatedTime(parseInt(e.target.value) || null)}
                  fullWidth
                  variant="outlined"
                  sx={{
                    borderRadius: '16px',
                    fontSize: { xs: '0.8rem', sm: '1rem' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimerIcon sx={{ color: '#FFBF5D', fontSize: { xs: '1rem', sm: '1.5rem' } }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Servings"
                  type="number"
                  value={servings || ''}
                  onChange={(e) => setServings(parseInt(e.target.value) || null)}
                  fullWidth
                  variant="outlined"
                  sx={{
                    borderRadius: '16px',
                    fontSize: { xs: '0.8rem', sm: '1rem' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PeopleIcon sx={{ color: '#FFBF5D', fontSize: { xs: '1rem', sm: '1.5rem' } }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleAddIngredient}
              placeholder="Type ingredients and press Enter..."
              fullWidth
              variant="outlined"
              sx={{
                marginBottom: '10px',
                borderRadius: '16px',
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Add Ingredient">
                      <IconButton
                        onClick={() => {
                          if (inputValue.trim()) {
                            setIngredients([...ingredients, inputValue.trim()]);
                            setInputValue('');
                          }
                        }}
                        sx={{ color: '#FFBF5D', fontSize: { xs: '1rem', sm: '1.5rem' } }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {ingredients.map((ingredient, index) => (
                <Chip
                  key={index}
                  label={ingredient}
                  onDelete={() => handleRemoveIngredient(ingredient)}
                  sx={{
                    backgroundColor: '#FFBF5D',
                    color: 'white',
                    borderRadius: '16px', // Slightly smaller on mobile
                    fontSize: { xs: '0.8rem', sm: '1rem' }, // Responsive font size
                    '&:hover': { backgroundColor: '#e0a54d' },
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
            <Button
              onClick={handleSubmit}
              disabled={loading || ingredients.length === 0}
              variant="contained"
              sx={{
                backgroundColor: '#FFBF5D',
                color: 'white',
                padding: { xs: '8px 16px', sm: '12px 24px' }, // Smaller padding on mobile
                borderRadius: '16px',
                fontSize: { xs: '0.8rem', sm: '1rem' },
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#e0a54d',
                  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
                },
              }}
              startIcon={<SearchIcon sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }} />}
            >
              SEARCH RECIPES
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: '20px', flexGrow: 1 }}>
          {error && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: '10px', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
              {error}
            </Alert>
          )}

          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
              <CircularProgress />
            </Box>
          )}

          {/* Recipe rendering logic */}
          {!loading && recipes.length > 0 && recipes.map((recipe, index) => (
            <Accordion
              key={index}
              sx={{
                marginTop: '10px',
                borderRadius: '16px',
                backgroundColor: '#ECECEA',
                padding: '16px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFBF5D' }} />}
                aria-controls={`recipe-content-${index}`}
                id={`recipe-header-${index}`}
                sx={{ padding: 0 }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Abhaya Libre, serif',
                    color: '#333',
                    '&:hover': {
                      color: '#222',
                    },
                  }}
                >
                  {recipe.title}
                </Typography>
                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '10px', color: 'gray' }}>
                  <AccessTimeIcon sx={{ color: '#FFBF5D' }} />
                  <Typography variant="body2" sx={{ fontFamily: 'Abhaya Libre, serif', color: '#555' }}>
                    {recipe.estimated_time_minutes} mins
                  </Typography>
                  <RestaurantIcon sx={{ color: '#FFBF5D' }} />
                  <Typography variant="body2" sx={{ fontFamily: 'Abhaya Libre, serif', color: '#555' }}>
                    {recipe.serves}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Box sx={{ marginBottom: '10px' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      fontFamily: 'Abhaya Libre, serif',
                      color: '#333',
                    }}
                  >
                    <LocalDiningIcon sx={{ marginRight: '5px', color: '#FFBF5D' }} /> Ingredients
                  </Typography>
                  <List sx={{ paddingLeft: '20px' }}>
                    {recipe.ingredients.map((ingredient: string, ingredientIndex: number) => (
                      <ListItem
                        key={ingredientIndex}
                        sx={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          '&:hover': {
                            backgroundColor: 'rgba(255, 191, 93, 0.1)',
                            borderRadius: '8px',
                          },
                        }}
                      >
                        <FiberManualRecordIcon sx={{ fontSize: '8px', marginRight: '10px', color: '#FFBF5D' }} />
                        <ListItemText primary={ingredient} sx={{ fontFamily: 'Abhaya Libre, serif', color: '#555' }} />
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Divider sx={{ marginBottom: '10px' }} />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      fontFamily: 'Abhaya Libre, serif',
                      color: '#333',
                    }}
                  >
                    <TimerIcon sx={{ marginRight: '5px', color: '#FFBF5D' }} /> Steps
                  </Typography>
                  <List sx={{ paddingLeft: '20px' }}>
                    {recipe.instructions.map((step: string, stepIndex: number) => (
                      <ListItem
                        key={stepIndex}
                        sx={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          '&:hover': {
                            backgroundColor: 'rgba(255, 191, 93, 0.1)',
                            borderRadius: '8px',
                          },
                        }}
                      >
                        <ListItemText primary={`Step ${stepIndex + 1}: ${step}`} sx={{ fontFamily: 'Abhaya Libre, serif', color: '#555' }} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default App;
